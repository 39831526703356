import { useState, useEffect, useRef, useCallback } from 'react';
import { IntersectionObserver } from 'client/utils/intersection-observer';

// Checks if the element is visible
export function useOnScreen(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  const disconnect = useCallback(() => {
    if (observer.current) {
      observer.current.disconnect();
      observer.current = null;
      setIntersecting(false);
    }
  }, []);

  useEffect(() => {
    if (IntersectionObserver) {
      observer.current = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (observer.current && ref && ref.current) {
      observer.current.observe(ref.current);
    }
  }, [ref]);

  useEffect(() => () => disconnect(), [disconnect]);

  return [isIntersecting, disconnect];
}
