import React, { useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import debounce from 'lodash/debounce';
import Button from 'reactstrap/lib/Button';
import { Element, scroller } from 'react-scroll'; // eslint-disable-line no-restricted-syntax
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { Collapse } from 'site-modules/shared/components/collapse/collapse';

import './content-collapse.scss';

const TRANSITION_DELAY = 280;

export function ContentCollapse({
  minHeight,
  isEclipseFade,
  children,
  classes,
  collapseClassName,
  showBtnBelow,
  trackingId,
  collapseTrackingId,
  trackingValue,
  noAnim,
  truncatedChildren,
  overflowOnExpanded,
  isOpenOnLoad,
  btnClasses,
  btnColor,
  btnContainerClasses,
  btnArrowClasses,
  btnArrowIconClasses,
  btnTextClasses,
  textOpen,
  textClosed,
  justify,
  hideIndicator,
  hideCollapseBtn,
  btnTrackingId,
  btnTrackingValue,
  btnIgnoreTrackingValue,
  btnSize,
  isSameExpandCollapseTracking,
  isOnlyExpandTracking,
  ariaLabelHeading,
  accessibilityCallback,
  resetParams,
  scrollToDefault,
  onToggleContentCollapseState,
  collapseToggleSrExplanation,
  renderCollapseControl,
}) {
  const [isOpen, toggleOpen] = useToggle(isOpenOnLoad);
  const collapseId = `collapse${useId()}`;

  useEffect(() => {
    function onResize() {
      accessibilityCallback(isOpen);
    }

    if (accessibilityCallback) {
      accessibilityCallback(isOpen);
      window.addEventListener('resize', onResize, false);

      return () => {
        window.removeEventListener('resize', onResize, false);
      };
    }

    return noop;
  }, [accessibilityCallback, isOpen]);

  const toggle = debounce(e => {
    e.preventDefault();

    if (resetParams && isOpen) {
      scroller.scrollTo(resetParams.to || (scrollToDefault && collapseId), {
        duration: resetParams.duration,
        offset: resetParams.offset,
        smooth: 'easeInOutQuad',
        isDynamic: true,
      });
    }
    onToggleContentCollapseState(isOpen);

    toggleOpen();
  }, 10);

  function passChildren(childrenToRender) {
    return typeof childrenToRender === 'function' ? childrenToRender({ isOpen }) : childrenToRender;
  }

  const trackingIdSuffix = isOpen ? '_collapse' : '_expand';
  const btnCollapseTrackingId = btnTrackingId
    ? `${btnTrackingId}${isSameExpandCollapseTracking ? '' : trackingIdSuffix}`
    : null;
  const controlText = isOpen ? textOpen : textClosed;
  const collapseButton = !(isOpen && hideCollapseBtn) && (
    <div className={classnames('collapse-control mb-0 row align-items-center', justify, btnContainerClasses)}>
      {renderCollapseControl && renderCollapseControl({ isOpen, onToggle: toggle })}
      {!renderCollapseControl && (
        <Button
          size={btnSize}
          color={btnColor}
          className={classnames('content-collapse-button', btnClasses)}
          onClick={toggle}
          data-tracking-id={isOnlyExpandTracking && isOpen ? null : btnCollapseTrackingId}
          data-tracking-value={btnTrackingValue}
          data-no-refresh
          aria-expanded={isOpen}
          {...(btnIgnoreTrackingValue ? { 'data-tracking-ignore-value': true } : {})}
          aria-label={ariaLabelHeading ? `${controlText} ${ariaLabelHeading}` : null}
        >
          <span className={classnames('control-text', btnTextClasses, { 'mr-0': hideIndicator })}>{controlText}</span>
          {collapseToggleSrExplanation && <span className="sr-only">{collapseToggleSrExplanation}</span>}
          {!hideIndicator && (
            <span
              className={classnames(btnArrowClasses, isOpen ? btnArrowIconClasses.up : btnArrowIconClasses.down)}
              aria-hidden={!ariaLabelHeading}
            />
          )}
        </Button>
      )}
    </div>
  );

  return (
    <div
      className={classnames('content-collapse', classes, { isEclipseFade })}
      data-tracking-id={isOpen && collapseTrackingId ? collapseTrackingId : trackingId}
      data-tracking-value={trackingValue}
    >
      <Element id={collapseId} />
      {!showBtnBelow && collapseButton}
      <Collapse
        className={collapseClassName}
        isOpen={isOpen}
        collapseHeight={truncatedChildren ? null : minHeight}
        addState
        noAnim={noAnim}
        overflowOnExpanded={overflowOnExpanded}
        transition={`height ${TRANSITION_DELAY}ms cubic-bezier(0.4, 0, 0.2, 1)`}
      >
        {!truncatedChildren || isOpen ? passChildren(children) : passChildren(truncatedChildren)}
      </Collapse>
      {showBtnBelow && collapseButton}
    </div>
  );
}

ContentCollapse.propTypes = {
  isOpenOnLoad: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  minHeight: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
  truncatedChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  textClosed: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isEclipseFade: PropTypes.bool,
  classes: PropTypes.string,
  collapseClassName: PropTypes.string,
  btnClasses: PropTypes.string,
  btnColor: PropTypes.string,
  btnContainerClasses: PropTypes.string,
  btnArrowClasses: PropTypes.string,
  btnArrowIconClasses: PropTypes.shape({
    up: PropTypes.string,
    down: PropTypes.string,
  }),
  btnTextClasses: PropTypes.string,
  btnSize: PropTypes.string,
  showBtnBelow: PropTypes.bool,
  justify: PropTypes.string,
  trackingId: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  btnTrackingId: PropTypes.string,
  btnTrackingValue: PropTypes.string,
  btnIgnoreTrackingValue: PropTypes.bool,
  resetParams: PropTypes.shape({
    offset: PropTypes.number,
    duration: PropTypes.number,
    to: PropTypes.string,
  }),
  hideCollapseBtn: PropTypes.bool,
  scrollToDefault: PropTypes.bool,
  noAnim: PropTypes.bool,
  overflowOnExpanded: PropTypes.bool,
  onToggleContentCollapseState: PropTypes.func,
  isSameExpandCollapseTracking: PropTypes.bool,
  isOnlyExpandTracking: PropTypes.bool,
  ariaLabelHeading: PropTypes.string,
  collapseToggleSrExplanation: PropTypes.string,
  accessibilityCallback: PropTypes.func,
  renderCollapseControl: PropTypes.func,
};

ContentCollapse.defaultProps = {
  isOpenOnLoad: false,
  hideIndicator: false,
  minHeight: '0px',
  textClosed: 'Read more',
  textOpen: 'Read less',
  isEclipseFade: true,
  classes: '',
  collapseClassName: undefined,
  btnClasses: '',
  btnColor: 'link',
  btnContainerClasses: 'py-0_5',
  btnArrowClasses: 'size-10 mb-0',
  btnArrowIconClasses: {
    up: 'icon-arrow-up4',
    down: 'icon-arrow-down4',
  },
  btnTextClasses: '',
  btnSize: 'sm',
  showBtnBelow: false,
  justify: 'justify-content-center',
  trackingId: 'view_content_summary',
  collapseTrackingId: undefined,
  trackingValue: null,
  btnTrackingId: null,
  btnTrackingValue: undefined,
  btnIgnoreTrackingValue: true,
  resetParams: null,
  hideCollapseBtn: false,
  scrollToDefault: false,
  truncatedChildren: null,
  noAnim: false,
  overflowOnExpanded: false,
  onToggleContentCollapseState: noop,
  isSameExpandCollapseTracking: false,
  isOnlyExpandTracking: false,
  ariaLabelHeading: null,
  accessibilityCallback: null,
  collapseToggleSrExplanation: null,
  renderCollapseControl: undefined,
};
