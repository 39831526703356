import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from 'reactstrap/lib/Table';
import { defaultTo, get } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ShareThisChart } from 'client/site-modules/shared/components/share-this-chart/share-this-chart';
import { compareValues, getTablePropsFromContent } from './article-table-helpers';

import './article-table.scss';

const POSSIBLE_ALIGN = ['left', 'right', 'center'];
const SORT_ORDER = {
  NONE: 'none',
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export function ArticleTable({ entry, className, shareOptions }) {
  const { canonical, pageTitle, contentId } = shareOptions;
  const entryPath = defaultTo(shareOptions.entryPath, []);
  const {
    title,
    creativeId,
    TitleTag,
    rowCount,
    columnCount,
    columnCategoriesBackgrounds,
    hasCategories,
    columnHeaderBackgrounds,
    columnWidths,
    fontSize,
    noVerticalBorders,
    noRowHeaders,
    isStriped,
    isSmall,
    columnsAlign,
    headersAlign,
    hasVerticalAlignment,
    categoriesStartIndexes,
    columnCategories,
    verticalAlign,
  } = getTablePropsFromContent(entry);
  const initialRowValues = new Array(rowCount).fill().map((value, index) =>
    entry
      .metadata(`row${index + 1}Values`)
      .value()
      .split('|')
  );

  const [sortColumn, setSortColumn] = useState(
    new Array(columnCount)
      .fill()
      .map((v, index) => index)
      .find(columnIndex => !!entry.metadata(`columnSort${columnIndex + 1}Initial`).value())
  );
  const [sortOrder, setSortOrder] = useState(entry.metadata(`columnSort${sortColumn + 1}Initial`).value());
  const [rowValues, setRowValues] = useState(
    sortOrder
      ? initialRowValues.sort((a, b) =>
          compareValues(a[sortColumn], b[sortColumn], sortOrder === SORT_ORDER.DESCENDING)
        )
      : initialRowValues
  );
  const [tableSizes, setTableSizes] = useState({});
  const ref = useRef();

  useEffect(() => {
    setTableSizes({ height: +get(ref, 'current.clientHeight', 0), width: +get(ref, 'current.clientWidth', 0) });
  }, []);

  function onColumnSort(event) {
    const columnIndex = parseInt(event.currentTarget.dataset.index, 10);

    if (columnIndex === sortColumn) {
      setSortOrder(sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING);
      setRowValues(rowValues.reverse());

      return;
    }

    setSortColumn(columnIndex);
    setSortOrder(SORT_ORDER.ASCENDING);
    setRowValues(rowValues.sort((a, b) => compareValues(a[columnIndex], b[columnIndex])));
  }

  if (!columnCount) {
    return null;
  }

  return (
    <div ref={ref} data-tracking-parent={creativeId}>
      <ShareThisChart
        pageTitle={pageTitle}
        pageUrl={canonical}
        shareText="Share this table"
        shareableWidgetData={{
          backLink: canonical,
          defaultTitle: pageTitle,
          widgetName: 'tables',
          hostPageOverride: 'https://www.edmunds.com/widgets/tables/',
          queryParams: { contentId, entryPath: entryPath.join(',') },
          defaultWidth: tableSizes.width,
          defaultHeight: tableSizes.height,
        }}
        className="text-primary-darker text-right ml-auto"
      />
      <div style={{ fontSize }} className={className}>
        {!!title && <TitleTag className="heading-3 mb-0_5">{title}</TitleTag>}
        <Table
          responsive
          striped={isStriped}
          bordered={!noVerticalBorders && !hasCategories}
          size={isSmall ? 'sm' : undefined}
          className={classnames('article-table text-gray-darker', { 'has-categories': hasCategories })}
        >
          {columnWidths.length === columnCount && (
            <colgroup>
              {columnWidths.map((width, index) => {
                const key = `${entry.id}-colgroup-${index}`;

                return <col key={key} width={width} />;
              })}
            </colgroup>
          )}
          <thead>
            {hasCategories && (
              <tr>
                {columnCategories.map(({ value, colspan }, index) => {
                  const key = `${entry.id}-category-${index}`;

                  return (
                    <th
                      key={key}
                      colSpan={colspan}
                      scope={colspan === 1 ? 'col' : 'colgroup'}
                      className={classnames('text-center align-middle', { 'category-border': !!index })}
                      {...(columnCategoriesBackgrounds[index]
                        ? { style: { background: columnCategoriesBackgrounds[index] } }
                        : {})}
                    >
                      <ContentFragment>{value}</ContentFragment>
                    </th>
                  );
                })}
              </tr>
            )}
            <tr>
              {new Array(columnCount).fill().map((value, index) => {
                const key = `${entry.id}-header-${index}`;
                const hasAlignment = POSSIBLE_ALIGN.includes(headersAlign[index]);
                const isSortable = entry.metadata(`columnSort${index + 1}`).boolean();
                const headerText = (
                  <ContentFragment>{entry.metadata(`columnHeader${index + 1}`).value()}</ContentFragment>
                );
                const defaultSortOrder = isSortable ? SORT_ORDER.NONE : null;
                const hasCategoryBorder = !!index && categoriesStartIndexes.includes(index);

                return (
                  <th
                    scope="col"
                    className={classnames('align-middle', `text-${hasAlignment ? headersAlign[index] : 'center'}`, {
                      'border-left': hasCategories && !!index && !hasCategoryBorder,
                      'category-border': hasCategoryBorder,
                    })}
                    key={key}
                    aria-sort={sortColumn === index ? sortOrder : defaultSortOrder}
                    {...(columnHeaderBackgrounds[index]
                      ? { style: { background: columnHeaderBackgrounds[index] } }
                      : {})}
                  >
                    {isSortable ? (
                      <button
                        className="sort-btn py-0 pl-0 pr-1_25 pos-r border-0 text-gray-darker font-weight-bold"
                        onClick={onColumnSort}
                        data-index={index}
                      >
                        {headerText}
                        <i className="arrow icon-arrow-up5" aria-hidden />
                        <i className="arrow icon-arrow-down5" aria-hidden />
                      </button>
                    ) : (
                      headerText
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rowValues.map((columnValues, index) => {
              const key = `${entry.id}-row-${index}`;

              return (
                <tr key={key}>
                  {columnValues.map((columnValue, columnIndex) => {
                    const columnKey = `${key}-column-${columnIndex}`;
                    const hasAlignment = POSSIBLE_ALIGN.includes(columnsAlign[columnIndex]);
                    const hasCategoryBorder = !!columnIndex && categoriesStartIndexes.includes(columnIndex);

                    let cellProps = { componentToUse: 'td' };
                    if (!noRowHeaders && !columnIndex) {
                      cellProps = { componentToUse: 'th', componentProps: { scope: 'row' } };
                    }

                    return (
                      <ContentFragment
                        {...cellProps}
                        classes={classnames({
                          [`text-${columnsAlign[columnIndex]}`]: hasAlignment,
                          [`align-${verticalAlign}`]: hasVerticalAlignment,
                          'border-left': hasCategories && !!columnIndex && !hasCategoryBorder,
                          'category-border': hasCategoryBorder,
                        })}
                        key={columnKey}
                      >
                        {columnValue}
                      </ContentFragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

ArticleTable.propTypes = {
  entry: CmsEntities.Content,
  className: PropTypes.string,
  shareOptions: PropTypes.shape({}),
};

ArticleTable.defaultProps = {
  entry: DEFAULT_CONTENT,
  className: null,
  shareOptions: {},
};
