import { get } from 'lodash';
import { stripHtml } from 'client/utils/string-utils';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

const NUMBER_REGEX = /-?\d+(\.\d*)?/g;
const POSSIBLE_VERTICAL_ALIGN = ['top', 'middle', 'bottom'];
const TITLE_TAG_MAP = {
  ContentFragment,
};

const removeHtml = str => stripHtml(str.replace(/&lt;/g, '<').replaceAll(/&gt;/g, '>'));
export const compareStrings = (a, b) => removeHtml(a).localeCompare(removeHtml(b));

export const compareValues = (a, b, isDescending) => {
  const floatA = a && parseFloat(get(a.match(NUMBER_REGEX), '[0]'));
  const floatB = b && parseFloat(get(b.match(NUMBER_REGEX), '[0]'));

  let compareValue;

  if (floatA && floatB) {
    compareValue = floatA - floatB;
  } else {
    compareValue = a < b ? -1 : 1;
  }

  return isDescending ? compareValue * -1 : compareValue;
};

export const getTablePropsFromContent = content => {
  const title = content.metadata('title').value();
  const creativeId = content.metadata('creativeId').value() || undefined;
  const titleTag = content.metadata('titleTag').value('div');
  const TitleTag = TITLE_TAG_MAP[titleTag] || titleTag;

  const rowCount = content.metadata('rowCount').number();
  const columnCount = content.metadata('columnCount').number();
  const columnCategories = content
    .metadata('columnCategories')
    .value()
    .split('||')
    .map(category => {
      const [value, colspan] = category.split('|');
      return { value, colspan: parseInt(colspan, 10) || 1 };
    })
    .filter(({ value }) => value);
  const columnCategoriesBackgrounds = content
    .metadata('columnCategoriesBackgrounds')
    .value()
    .split('|');

  const categoriesStartIndexes = [];
  columnCategories.forEach(({ colspan }) => {
    categoriesStartIndexes.push(get(categoriesStartIndexes, `[${categoriesStartIndexes.length - 1}]`, 0) + colspan);
  });
  const hasCategories = !!columnCategories.length;

  const columnHeaderBackgrounds = content
    .metadata('columnHeaderBackgrounds')
    .value()
    .split('|');

  const columnWidths = content
    .metadata('columnWidths')
    .value()
    .split('|');
  const fontSize = content.metadata('fontSize').value('1rem');
  const noVerticalBorders = content.metadata('noVerticalBorders').boolean();
  const noRowHeaders = content.metadata('noRowHeaders').boolean();
  const isStriped = content.metadata('striped').boolean();
  const isSmall = content.metadata('small').boolean();
  const columnsAlign = content
    .metadata('columnsAlign')
    .value()
    .split('|');
  const headersAlign = content
    .metadata('headersAlign')
    .value()
    .split('|');

  const verticalAlign = content.metadata('verticalAlign').value();
  const hasVerticalAlignment = POSSIBLE_VERTICAL_ALIGN.includes(verticalAlign);

  return {
    title,
    creativeId,
    TitleTag,
    rowCount,
    columnCount,
    columnCategoriesBackgrounds,
    hasCategories,
    columnHeaderBackgrounds,
    columnWidths,
    fontSize,
    noVerticalBorders,
    isStriped,
    isSmall,
    noRowHeaders,
    columnsAlign,
    headersAlign,
    hasVerticalAlignment,
    categoriesStartIndexes,
    columnCategories,
    verticalAlign,
  };
};
